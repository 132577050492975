import React from 'react'
import { graphql, Link } from 'gatsby'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { column } from 'styles/column'
import { Grid } from 'components/grid'
import H2 from 'components/typography/h2'
import Section from 'components/section'
import { GatsbyImage } from 'gatsby-plugin-image'

const Promos = ({ data, type, ...props }) => {
  const promos = []
  if (data.length === 0) {
    return <></>
  }
  for (let i = 0; i < (data.length < 3 ? data.length : 3); i++) {
    let title = data[i].title
    if (data[i].subTitle) {
      title = `${title} ${data[i].subTitle}`
    }
    promos.push({
      title,
      image: data[i].image,
      link: data[i].link || data[i].slug,
    })
  }
  return (
    <Section {...props}>
      <H2>{type} Promotions</H2>
      <Grid>
        {promos.map((promo, key) => (
          <Promo key={key}>
            <PromoLink to={promo.link}>
              <Title dangerouslySetInnerHTML={{ __html: promo.title }} />
            </PromoLink>
            <img
              src={promo.image}
              alt=''
              style={{
                objectFit: 'cover',
                objectPosition: '50% 50%',
              }}
            />
          </Promo>
        ))}
      </Grid>
    </Section>
  )
}

Promos.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object.isRequired,
      link: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string,
    })
  ).isRequired,
  type: PropTypes.string.isRequired,
}

const Promo = styled.div`
  height: 180px;
  position: relative;

  :not(:last-of-type) {
    margin-bottom: 15px;
  }

  @media ${props => props.theme.mediaQuery.mediumUp} {
    ${column('1/3')}
    height: 214px;

    :not(:last-of-type) {
      margin-bottom: 0;
    }
  }
`

const PromoLink = styled(Link)`
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
  color: #fff;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;

  :hover,
  :focus {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
  }
`

const PromoImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  z-index: 0;
`

const Title = styled.h3`
  color: #fff;
  font-size: 1.5em;
  left: 50%;
  max-width: none;
  position: absolute;
  text-align: center;
  text-transform: none;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
`

export const HutsonPromoFragment = graphql`
  fragment HutsonPromo on HutsonPromotion {
    endDateString
    image
    link
    shortDescription
    slug
    tags
    title
  }
`

export const DeerePromoFragment = graphql`
  fragment DeerePromo on DeereOffer {
    image: localImage {
      ...SharpImage680
    }
    slug
    subTitle
    title
  }
`

export default Promos
